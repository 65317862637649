
.center,
.container,
.hdbox_r p,
body {
  margin: auto;
}



.rcontainer{
    /* width: 1300px; */
}
.top-bar {
  float: right;
  padding: 0;
  height: 35px;
  overflow: hidden;
}
.top-bar ul li {
  display: inline-flex;
  padding: 0;
  margin: 0 5px;
  line-height: 1;
}
ul.top-partner {
  margin: 0 15px 0 0;
  font-size: 16px;
}
ul.top-partner li a {
  color: #e51c38;
  padding: 10px 5px;
}
.cont_info_page a:hover,
.evbox_txt i,
.menucol_sm a:hover,
.openings_txt a,
.qf_txt span,
.social_address a:hover,
ul.top-social li a {
  color: #005E8C;
}
.smtop_menu {
  margin: 0;
}
#textbox,
.menu-sub-link {
  margin-bottom: 20px;
}
.smtop_menu li a {
  color: #fff;
  font-size: 20px;
}
.smtop_menu li a:hover {
  border-bottom: 2px solid #fff;
}
.smtop_menu li {
  line-height: 1;
  list-style: none;
  border-left: 1px solid #fff;
  padding: 0 15px;
}
.header_active .smtop_menu li a {
  color: #fff;
  font-size: 18px;
}
.smtop_menu li:first-child {
  border-left: 0 solid #fff;
}
header {
  top: 0;
  z-index: 888;
}
header .navWrapper {
  width: 85%;
  margin: auto;
  padding-top: 15px;
}
.accordion_2 .accordion-content,
.accordion_2 .accordion-header-icon,
.banner_d,
.desktop-img img,
.dropdown li,
.dropdown li a,
.ims_certificate_col div a,
.menu_acc_2 a,
.mobile-menu-hide,
.no-js .owl-carousel,
.ong_img img,
.owl-carousel.owl-loaded,
.site_survey_box.open,
.solar_box.open,
.vidoe_box a,
header #logo,
img.desktop-arrow {
  display: block;
}
header #nav-icon {
  width: 95px;
  position: relative;
  z-index: 2;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  margin-top: 0;
}
header #nav-icon span {
  display: block;
  position: absolute;
  height: 9px;
  width: 100%;
  background: #292929;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
header #nav-icon span:first-child {
  top: 0;
  transform-origin: left center;
}
header #nav-icon span:nth-child(2) {
  top: 18px;
  transform-origin: left center;
}
header #nav-icon span:nth-child(3) {
  top: 36px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
header #nav-icon.open span:first-child {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
  background: #fff;
}
header #nav-icon.open span:nth-child(2) {
  width: 0;
  opacity: 0;
  background: #fff;
}
header #nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
  background: #fff;
}
header .overlay {
  width: 100%;
  /* background: url(../images/design-bg/bg-menu.jpg) bottom no-repeat #fff; */
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  align-items: center;
  padding: 10px 40px;
  display: flex;
  height: 0;
  overflow-y: auto;
}
.author_2 h4 a,
.click_x,
.core-menu,
.internal_col_box a,
.internal_owl .owl-wrapper-outer,
.nu-col-box_bg,
.testimonial_slider_2,
.vidoe_box,
section {
  overflow: hidden;
}
header .overlay.open {
  opacity: 1;
  visibility: visible;
  z-index: 1;
  height: 100%;
}
header .meu_1 {
  transform: 0.5s;
  color: #fff;
  /* background: #f39021; */
  padding: 2px 5px;
  border-radius: 7px;
}
header .meu_1 img {
  margin: 0 0 -8px;
}
header .meu_2 {
  display: none;
  transform: 0.5s;
  text-align: right;
}
header .open .meu_2 {
  padding: 0;
  display: block;
}
header .open .meu_2 img {
  width: 40px;
}
.hr_swo {
  position: relative;
  z-index: 22;
}
li.hr_swo a {
  color: #f68823;
}
header .content-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 70%;
  transform: translate(-50%, -50%);
  text-align: center;
}
header .banner-section .content-inner .hero-text {
  color: #fff;
  font-size: 4rem;
  padding: 0 0.5em;
  line-height: 1.25em;
  display: block;
  font-weight: 300;
}
header .banner-section .content-inner p {
  color: #fff;
  font-size: 2rem;
}
.menu_min {
  padding: 60px 0 10px;
}
.menucol_sm {
  color: #444;
  padding: 0 20px;
}
.menucol_sm a {
  color: #444;
}
.menucol_sm h2 {
  font-size: 20px;
  margin: 0 0 5px;
  padding: 0;
  font-weight: 600;
  color: #e51c38;
}
.menu-sub-link:last-child {
  margin-bottom: 0;
}
.navlink a {
  width: 230px;
  font-weight: 500;
  color: #e51c38;
  display: block;
  padding: 0 10px;
  margin: 0;
  font-size: 25px;
}
.navlink .min_box-tx a {
  font-size: 20px;
  width: 250px;
  color: #444;
}
.menucol_1 {
  float: left;
  margin: 0 3% 0 0;
  width: 45%;
  border-right: 1px solid #000;
}
.menucol_3 {
  width: 30%;
  float: left;
}
.menucol_4 {
  width: 22%;
  float: left;
}
.uslik-1 a {
  display: block;
  line-height: 1;
  margin: 8px 0;
}
.uslik-2 {
  margin: 10px 0 0;
}
.uslik-2 a {
  padding: 5px;
  font-size: 22px;
  line-height: 1;
}
.menucol_4 .font_s2 {
  padding: 10px 0 0;
}
.colst_box {
  margin: 0 0 25px;
}
ol.number-list {
  display: block;
  margin: 0;
  padding: 0 0 0 15px;
}
ol.number-list li {
  width: 100%;
  padding: 5px 0;
  margin: 0;
  list-style: decimal;
}
.nav-menu-top ul ul {
  display: none;
  position: absolute;
  top: 68px;
  padding-top: 10px;
}
.nav-menu-top ul li:hover > ul,
.tab-content.current {
  display: inherit;
}
.nav-menu-top ul ul li a {
  border-bottom: 0;
  display: block;
  padding: 5px;
  font-size: 16px;
}
.nav-menu-top ul ul li a:hover {
  border-bottom: 0;
}
.nav-menu-top ul ul li {
  width: 170px;
  background: #e51c38;
  text-align: center;
  float: none;
  padding: 5px 0;
  display: list-item;
  position: relative;
  border: 0;
  left: -35px;
}
.nav-menu-top ul ul ul li {
  position: relative;
  top: -60px;
  left: 170px;
}
.nav-menu-top li > a:after {
  /* content: url(../images/icon/down-icon.png); */
}
.nav-menu-top li > a:only-child:after,
.slick-next:before,
.slick-prev:before {
  content: "";
}
.core-menu {
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 0;
}
.core-menu li {
  list-style: none;
  width: 210px;
  cursor: pointer;
  margin: 0;
}
.core-menu li ul li {
  list-style: none;
  width: auto;
  margin: 0 0 0 5px;
}
.core-menu li ul li a {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.1;
  padding: 10px;
}
.core-menu li a {
  display: block;
  font-size: 22px;
  font-weight: 600;
  padding: 5px 10px;
  color: #444;
}
li span.toggle {
  padding-left: 0;
  float: right;
}
.toggle:before {
  /* content: url(../images/icon/down-icon-b.png); */
}
.dropdown {
  position: absolute;
  left: 200px;
  margin: -40px 0 0;
  text-align: left;
  display: none;
  z-index: 0;
}
.bd-box,
.click_x,
.nu-row {
  display: flex;
}
.tab {
  float: left;
  /* width: 30%; */
  /* height: 400px; */
  /* border-radius: 10px 0 0 10px; */
}
.tab button {
  display: block;
  background-color: inherit;
  color: #000;
  padding: 22px 16px;
  width: 95%;
  border: none;
  outline: 0;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
  margin: 5%;
  border-bottom: 3px solid red;
  opacity: 0.5;
}
.tab button.active {
  border-bottom: 3px solid #e51c38;
  opacity: 1;
}
.bd-box {
  flex-wrap: nowrap;
}
.bd_box_right {
  text-align: left;
  padding-left: 40px;
}
.accordion_2 .accordion-header,
.click_x,
.dots-center,
.evbox_bg_dt,
.few_proj_txt,
.foot-links_social,
.hashtag,
.icon-testimo,
.internal_owl .owl-pagination,
.misscall,
.openings_txt,
.testimonial_slider_2 #controls,
.title-center {
  text-align: center;
}
.bd_box_right h3 {
  color: #e51c38;
  font-weight: 600;
  padding: 0;
}
.author_2 h3,
.author_2 h4 {
  padding: 0 0 5px;
  color: #333;
}
.bd_box_right p b,
.slider-mg p b {
  font-weight: 600;
  color: #414042;
  font-size: 15px;
}
.inv_video li p,
.privacy-policy {
  font-weight: 700;
}
.bd_box_right p {
  color: #414042;
  font-size: 15px;
}
.bd-tabs {
  margin: 60px 0 0;
  padding: 80px 0 20px;
  /* background: url(../images/icon/verticle_line.png) top left/3% repeat-y #f1f1f1; */
}
.shape_bottom {
  position: absolute;
  right: 0;
  bottom: 1%;
}
.author_2 h4 a,
.content_2,
.inc_min_cov,
.inv_dw li a {
  position: relative;
}
.shape_bottom img {
  width: 130px;
}
.wp_bd {
  width: 86%;
  margin-top: 30px;
}
.testimonial_slider_2 {
  width: 100%;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  padding-bottom: 30px;
}
.testimonial_slider_2 #slide_2_1:checked ~ .boo_inner {
  margin-left: 0;
}
.testimonial_slider_2 #slide_2_2:checked ~ .boo_inner {
  margin-left: -100%;
}
.testimonial_slider_2 .boo_inner {
  width: 400%;
  -webkit-transform: translateZ(0);
  -webkit-transition: 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition: 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  transition: 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.testimonial_slider_2 .slide_content {
  width: 25%;
  float: left;
}
.testimonial_slider_2 #controls label {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  display: inline-block;
  background: #999;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.testimonial_2,
.testimonial_slider_2 #slide_2_1:checked ~ #controls label:first-child,
.testimonial_slider_2 #slide_2_2:checked ~ #controls label:nth-child(2) {
  font-size: 16px !important;
  color: #333;
}
.content_2 {
  padding-left: 30px;
  margin-bottom: 30px;
}
.content_2:after,
.content_2:before {
  content: "";
  height: 15px;
  width: 19px;
  position: absolute;
}
.content_2:before {
  /* background: url(icon_testimonial_2_before.png) no-repeat; */
  left: 0;
}
.content_2:after {
  /* background: url(icon_testimonial_2_after.png) no-repeat; */
  margin: 5px 10px;
}
.inv_video_img::after,
.vidoe_box a::before {
  /* background: url(../images/icon/video_btn.png) 0 0 / cover no-repeat; */
  left: 0;
  right: 0;
  top: 0;
}
.testimonial_2 p {
  display: inline;
}
.inv_dw li,
.inv_video li {
  width: 33%;
  display: inline-block;
  vertical-align: top;
}
.author_2 {
  margin-left: 30px;
}
.author_2 h3 {
  font-size: 18px;
  font-weight: 600px;
}
.author_2 h4 {
  font-size: 12px;
  font-weight: 400;
}
.author_2 h4 a {
  color: #999;
  padding-bottom: 1px;
  border-bottom: 1px solid;
}
.author_2 h4 a:after {
  background: #999;
  height: 1px;
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.author_2 h4 a:hover {
  color: #000;
  border: none;
}
.author_2 h4 a:hover:after {
  border-color: #000;
  width: 100%;
}
.inv_dw,
.inv_video {
  width: 90%;
  margin: auto;
  padding: 20px 0;
}
.inv_dw li {
  padding: 12px 50px;
}
.inv_dw li a {
  border-bottom: 1px solid #41404252;
  padding: 20px 40px 20px 5px;
  color: #414042;
  display: block;
}
.inv_dw li a::after {
  content: "";
  /* background: url(../images/icon/dw.jpg) 0 0 / cover no-repeat; */
  width: 17px;
  height: 17px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.inv_video li {
  padding: 15px;
}
.inv_video li a {
  display: block;
  color: #e51c38;
}
.evbox_bg,
.inv_video_img {
  position: relative;
  overflow: hidden;
}
.inv_video_img img,
.vidoe_box img {
  width: 100%;
  display: block;
  transition: 0.5s;
}
.inv_video_img::after {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 0;
  margin: auto;
}
.inv_video li p {
  margin: 5px 0;
}
.about_img a:hover img,
.inv_video li a:hover img,
.vidoe_box a:hover img {
  transform: scale(1.1);
}
.inv_src,
.owl_ongoing_project {
  padding-top: 40px;
}
.inc_min_cov {
  padding: 90px 0 0;
}
.icon_ir_1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 12%;
}
.icon_ir_2 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 12%;
}
.nu-row {
  margin: 20px 0 0;
}
.nu-col {
  width: 50%;
  margin: 0;
}
.evbox_bg img,
.nu-col img {
  transition: 0.9s;
  width: 100%;
  display: block;
}
.nu-col-box {
  padding: 10px;
}
.evbox_row .evbox a:hover .evbox_bg img,
.nu-col-box a:hover .nu-col-box_bg img {
  transition: 0.9s;
  transform: scale(1.5);
}
.nu-col-box h2 {
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  line-height: 33px;
  color: #e51c38;
}
.nu-col ul li {
  display: inline-block;
  vertical-align: top;
  width: 49%;
}
.nu-col ul li h2 {
  font-size: 16px;
  line-height: 20px;
}
.nu-col ul li p {
  font-size: 11px;
  margin: 5px 0;
}
.about_txt p,
.evbox_row,
.sol_bt {
  margin: 20px 0;
}
.evbox_row .evbox {
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  width: 33%;
}
.evbox_row .evbox a {
  display: block;
  border: 1px solid #ccc;
}
.evbox_bg_dt {
  position: absolute;
  background: #e51c38;
  color: #fff;
  padding: 10px 15px 5px;
  line-height: 29px;
  z-index: 1;
}
.evbox_bg_dt h2 {
  padding: 0;
  font-weight: 600;
  font-size: 35px;
}
.evbox_txt {
  color: #414042;
  padding: 15px;
}
.evbox_txt_l {
  float: left;
  font-size: 14px;
}
.evbox_txt_r {
  float: right;
  font-size: 14px;
}
.evbox_txt_f h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: #e51c38;
}
.evbox_txt_f p {
  color: #414042;
  margin: auto;
}
.bg_g {
  background: #f2f2f2;
}
.vidoe_box {
  position: relative;
  width: 90%;
  margin: 30px auto 0;
}
.vidoe_box a::before {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  bottom: 0;
  margin: auto;
  z-index: 2;
}
.vidoe_box a::after {
  content: "";
  background: #e51c38;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 500px;
  height: 500px;
  opacity: 0.2;
  transition: 0.9s;
  z-index: 1;
  transform: scale(0);
  border-radius: 100%;
}
.vidoe_box a:hover::after {
  transition: 0.9s;
  transform: scale(8.1);
}
.internal_row {
  display: flex;
  width: 100%;
  margin: 30px 0;
}
.internal_col_box {
  width: 100%;
  padding: 10px;
  position: relative;
}
.click_x,
.internal_col_box a::after,
.internal_col_txt {
  position: absolute;
  bottom: 0;
}
.internal_col_box a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
  cursor: zoom-in;
}
.internal_col_box a::after {
  background: #000;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 4%,
    rgba(0, 0, 0, 0.3253502084427521) 55%,
    rgba(0, 0, 0, 0.7707283597032564) 88%
  );
  content: "";
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.internal_col_box img {
  transition: 0.5s;
  width: 100%;
  display: block;
}
.internal_col_txt {
  width: 100%;
  padding: 20px;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #0000007d;
}
.internal_col2 {
  width: 70%;
}
.internal_col2 .internal_col_box a,
span {
  font-size: 20px;
}
.click_x {
  background: #0000008c;
  width: 0;
  height: 0;
  color: #fff;
  top: 0;
  margin: auto;
  left: 0;
  right: 0;
  align-items: center;
  align-content: center;
  border-radius: 100%;
  transition: 0.5s;
  font-size: 0;
  padding: 0;
}
.click_x p {
  margin: auto;
  line-height: 16px;
}
.internal_col_box a:hover .click_x {
  width: 120px;
  height: 120px;
  font-size: 12px;
  padding: 10px;
}
.internal_owl .owl-pagination {
  margin: auto;
}
.internal_owl .owl-page {
  display: inline-block;
  margin: 5px;
  width: 12px;
  height: 12px;
  background: #ccc;
  border-radius: 100%;
  font-size: 0;
  cursor: pointer;
}
.foot_3 .form_fot input,
.submit-btn {
  border: 1px solid #fff;
  font-size: 20px;
}
.internal_owl {
  width: 80% !important;
}
.internal_owl .owl-buttons div {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  /* background: url(../images/icon/home-arrow-left-b.png) bottom center no-repeat; */
}
.internal_owl .owl-prev {
  left: -5%;
  transform: rotate(-180deg);
}
.internal_owl .owl-next {
  right: -5%;
}
.internal_owl.owl-carousel {
  overflow: initial !important;
}
@media only screen and (max-width: 1000px) {
  .inv_dw,
  .inv_video {
    width: 100%;
  }
  .inv_dw li,
  .inv_video li {
    padding: 15px;
    width: 49%;
  }
}
@media only screen and (max-width: 800px) {
  .internal_row,
  .nu-row {
    display: block;
  }
  .internal_col1 {
    display: flex;
    width: 100%;
  }
  .internal_col_box {
    padding: 2px;
  }
  .nu-col,
  .vidoe_box {
    width: 100%;
  }
  .nu-col ul li h2 {
    font-size: 14px;
  }
  .nu-col-box h2 {
    font-size: 20px;
  }
  .nu-col-box {
    padding: 10px 2px;
  }
  .evbox_row .evbox {
    display: block;
    width: 100%;
    padding: 10px 0;
  }
  .internal_col_box a .click_x {
    width: 90px;
    height: 90px;
    padding: 5px;
  }
}
@media (max-width: 768px) {
  .core-menu,
  .core-menu li,
  .dropdown,
  .navigation {
    width: 100%;
  }
  .desk-menu-hide {
    display: block;
  }
  .mobile-menu-hide {
    display: none;
  }
  .dropdown {
    left: inherit;
    margin: 0;
    position: relative;
  }
  .core-menu {
    margin: 0;
  }
  .core-menu li a {
    margin: 0;
  }
}
.accordion_3 .accordion-item,
.accordion_4 .accordion-item_tab {
  position: relative;
  margin: 20px 0;
}
.accordion_3 .accordion-item-active .accordion-header,
.accordion_4 .accordion-item-active_tab .accordion-header_tab {
  transition: 0.25s;
}
.accordion_3 .accordion-header,
.accordion_4 .accordion-header_tab {
  z-index: 1;
  cursor: pointer;
  padding: 0;
  transition: 0.25s 0;
}
.accordion_3 .accordion-content,
.accordion_4 .accordion-content_tab {
  width: 100%;
  display: none;
}
.accordion_3 .accordion-header-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  display: block;
  top: 9px;
  right: 0;
}
.accordion_3 .accordion-header-icon.accordion-header-icon-active {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  width: 24px;
  height: 24px;
}
.accordion-header-icon {
  font-size: 0;
}
.menu_acc_col .menu_acc_2 {
  position: absolute;
  left: 250px;
  top: 0;
  visibility: hidden;
  height: 0;
}
.menu_acc_col:hover .menu_acc_2 {
  visibility: visible;
  height: auto;
}
@media screen and (min-width: 780px) {
  .menu_acc_col .menu_acc_2 {
    display: block !important;
  }
}
.nav_big {
  display: none;
  padding: 10px 0;
  position: relative;
}
.nav_big h1 {
  padding: 0;
}
.alignleft,
.minfot_col_1,
.nav_big1 {
  float: left;
}
.navbar_menu_btn {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
}
.fixed-header {
  position: fixed;
  top: 75px;
  background: #fff;
  z-index: 20;
  width: 100%;
}
.foot_3,
.foot_3 .form_fot {
  width: 60%;
  display: flex;
}
footer {
  padding: 0px 0 0;
  /* background: url(../images/design-bg/footer-bg.png) top right repeat-x #e51c38; */
}
.misscall {
  color: #fff;
}
.misscall h4 {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.misscall h4 a {
  color: #fff;
  font-weight: 600;
}
.misscall h4 a:hover {
  color: #f39021;
}
.misscall p {
  color: #fff;
  padding: 0;
  margin: 0;
  font-size: 22px;
}
.site_survey_conent,
.subscribe,
.what_new {
  padding: 30px 0;
}
.foot_3 .form_fot {
  background: #e51c38;
}
.foot_3 .form_fot input {
  color: #fff;
  width: 80%;
  padding: 5px 15px;
  background: #e51c38;
}
.foot_3 .form_fot input::placeholder {
  color: #fff;
}
.foo_hd {
  font-size: 20px;
  color: #fff;
  line-height: 1;
}
.submit-btn {
  color: #e51c38;
  display: block;
  width: 20%;
  height: 100%;
  padding: 5px 0;
  background: #fff;
  cursor: pointer;
}
.footer-3col,
.footer-4col {
  padding: 30px 0;
  width: 100%;
}
.footer-3col ul li,
.footer-4col ul li {
  float: left;
  text-align: center;
}
.footer-3col ul li:first-child,
.footer-3col ul li:nth-child(3) {
  width: 28%;
}
.footer-3col ul li:nth-child(2) {
  width: 44%;
}
.foot-links h5,
.foot-links_social h5 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
}
.foot-links a {
  transition: 0.3s;
  display: block;
  color: #fff;
  font-size: 16px;
}
.foot-links p {
  font-size: 16px;
  line-height: 1.2;
  color: #fff;
}
.foot-links a:hover,
.foot-links_social a:hover {
  opacity: 0.7;
}
.foot-links_social a {
  transition: 0.3s;
  color: #fff;
  display: inline-block;
  margin: 0 3px;
}
.footer-4col ul li:first-child,
.footer-4col ul li:nth-child(2),
.footer-4col ul li:nth-child(3),
.footer-4col ul li:nth-child(4) {
  width: 25%;
}
.min_footer {
  padding: 15px 0;
  background: #bd112c;
}
.hover_tab_col2 .is-active a,
.hover_tab_para p,
.min_footer a,
.title-bar.white h2 {
  color: #fff;
}
.minfot_col_1 a {
  padding: 0 10px;
  font-size: 14px;
}
.minfot_col_2 span {
  padding: 0 4px;
  font-size: 14px;
  color: #fff;
}
.btn-right,
.minfot_col_2,
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
.min_footer a strong,
.quick_stats_row ul li h3 span {
  font-weight: 600;
}
.openings_row {
  padding: 10px 5rem;
}
.current_openings,
.management_sect {
  padding: 80px 0 0;
}
.current_openings ul {
  margin: 0 0 0 20px;
  padding: 0;
}
.current_openings li {
  margin: 0 0 0 30px;
  padding: 0;
  list-style: disc;
}
.current_openings p strong {
  font-size: 18px;
  font-weight: 500;
}
.openings_txt {
  /* width: 90%; */
  margin: 0 auto;
  padding: 20px;
}
.accordion_2 .accordion-header {
  display: none;
  width: 70px;
  border-radius: 100px;
  height: 70px;
  background: #d2232a;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: -20px;
  left: 0;
  right: 0;
}
.minf_h {
  cursor: pointer;
}
.accordion_2 .accordion-header-icon {
  margin: -67px auto auto;
  float: none;
  font-size: 0;
  /* background: url(../images/icon/acd1_f.png) center/cover; */
  width: 13px;
  height: 13px;
}
.accordion_2 .accordion-header-icon-active {
  /* background: url(../images/icon/acd2.png) center/cover; */
  width: 13px;
  height: 13px;
}
.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
  overflow: hidden;
}
.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  display: inline-block;
  vertical-align: top;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s;
}
.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  /* background: url(owl.video.play.png) no-repeat; */
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s;
}
.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.hashtag {
  padding: 10px 0;
  background: #e51c38;
}
.testi,
.title-bar.white .dots-line span {
  background: #fff;
}
.hashtag h1,
.hashtag h2,
.hashtag h3 {
  font-size: 38px;
  color: #fff;
  font-weight: 600;
  margin: 0;
  padding: 0;
  line-height: 1;
}
.title-bar h2 {
  font-size: 30px;
  color: #005E8C;
  font-weight: 600;
  margin: 0;
  padding: 0;
  line-height: 1;
  text-transform: capitalize;
}
.title-bar h2 span {
  font-size: 24px;
  text-transform: initial;
  display: block;
  padding-top: 10px;
}
.dots-line span {
  width: 6px;
  display: inline-block;
  height: 6px;
  /* background: #005E8C; */
  margin: 0 2px;
  border-radius: 6px;
}
.home_banner {
  position: relative;
  padding: 35px 0 0;
}
.owl_homeslider .owl-pagination {
  position: absolute;
  bottom: 30px;
  left: 5%;
  right: 0;
  margin: auto;
  text-align: left;
}
.owl_homeslider .owl-page,
.owl_homeslider1 .owl-page,
.owl_testimonials .owl-page {
  display: inline-block;
  width: 15px;
  margin: 0 5px;
  cursor: pointer;
  height: 15px;
  background: #e51c38;
  border: 2px solid #e51c38;
  border-radius: 15px;
}
.owl_homeslider .owl-page.active,
.owl_homeslider1 .owl-page.active,
.owl_testimonials .owl-page.active {
  background: 0 0;
  border: 3px solid #e51c38;
}
.owl_homeslider .owl-buttons {
  display: flex;
  position: absolute;
  bottom: 80px;
  width: 100px;
  right: 3%;
}
.owl_acc_certifications .owl-next,
.owl_acc_certifications .owl-prev,
.owl_customers .owl-next,
.owl_customers .owl-prev,
.owl_homeslider .owl-next,
.owl_homeslider .owl-prev,
.owl_homeslider1 .owl-next,
.owl_homeslider1 .owl-prev,
.owl_ongoing_project .owl-next,
.owl_ongoing_project .owl-prev,
.owl_project .owl-next,
.owl_project .owl-prev,
.owl_testimonials .owl-next,
.owl_testimonials .owl-prev,
.owl_what_new .owl-next,
.owl_what_new .owl-prev,
.owl_what_new1 .owl-next,
.owl_what_new1 .owl-prev {
  width: 40px;
  height: 40px;
  margin: 0 5px;
}
.owl_customers .owl-next.disabled,
.owl_homeslider .owl-next.disabled,
.owl_homeslider1 .owl-next.disabled,
.owl_testimonials .owl-next.disabled {
  /* background: url(../images/icon/home-arrow-left.png) top center no-repeat; */
}
.owl_customers .owl-next,
.owl_homeslider .owl-next,
.owl_homeslider1 .owl-next,
.owl_testimonials .owl-next {
  cursor: pointer;
  /* background: url(../images/icon/home-arrow-left.png) bottom center no-repeat; */
}
.button-orng a,
.button-red a:hover,
.button-red-big a:hover,
.sol_bt button:hover,
.submit_btn_cont:hover,
.zcwf_button:hover {
  background: #f39021;
}
.owl_customers .owl-next:hover,
.owl_homeslider .owl-next:hover,
.owl_homeslider1 .owl-next:hover,
.owl_testimonials .owl-next:hover {
  /* background: url(../images/icon/home-arrow-left.png) bottom center no-repeat; */
}
.owl_customers .owl-prev:hover,
.owl_homeslider .owl-prev:hover,
.owl_homeslider1 .owl-prev:hover,
.owl_testimonials .owl-prev:hover {
  /* background: url(../images/icon/home-arrow-right.png) bottom center no-repeat; */
}
.owl_customers .owl-prev.disabled,
.owl_homeslider .owl-prev.disabled,
.owl_homeslider1 .owl-prev.disabled,
.owl_testimonials .owl-prev.disabled {
  /* background: url(../images/icon/home-arrow-right.png) top center no-repeat; */
}
.owl_homeslider1 .owl-pagination {
  position: absolute;
  bottom: 1%;
  left: 50%;
  right: 0;
  margin: auto;
  text-align: left;
}
.testi {
  padding: 30px;
  margin: 40px 0;
}
.testom {
  margin-top: 40px;
  padding: 70px 0 60px;
  background: #f2f2f2;
}
.slider_txt {
  position: absolute;
  left: 5%;
  bottom: 15%;
  margin: auto;
  padding: 10px 0;
  background-size: contain;
  width: 600px;
}
.slider_txt h2 {
  color: #fff;
  font-size: 55px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
}
.button-orng a,
.button-red a {
  padding: 5px 20px;
}
.slider_txt p {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}
.owl_homeslider1 .owl-buttons {
  position: absolute;
  bottom: 80px;
  width: 100px;
  right: 3%;
  display: none;
}
.button-orng a {
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
}
.button-red a,
.button-red-big a {
  background: #c61e38;
  text-transform: uppercase;
  transition: 0.4s;
  display: inline-block;
  color: #fff;
}
.button-red-big a {
  padding: 10px 30px;
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
}
.about_row {
  padding: 70px 70px 120px;
  /* background: url(../images/design-bg/about-bg.png) 0 0 / contain no-repeat; */
}
.about_img {
  float: left;
  width: 50%;
  position: relative;
  overflow: hidden;
}
.about_img img {
  width: 100%;
  transform: scale(1);
  transition: 0.4s;
  display: block;
}
.about_img a {
  display: block;
  position: relative;
  z-index: 5;
}
.about_txt {
  float: right;
  width: 45%;
}
.our_products_home {
  padding: 0 0 130px;
  /* background: url(../images/design-bg/product-bg.png) bottom right no-repeat; */
}
.contact_form_partner,
.product_home-contain,
.testimonials_section,
.what_new_home {
  padding: 60px 0 0;
}
ul.about_vission_box,
ul.about_vission_box2,
ul.product_home_box,
ul.product_home_box2 {
  margin: 0;
  padding: 0;
  display: flex;
}
ul.product_home_box li {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 50%;
  vertical-align: bottom;
}
ul.product_home_box li:last-child {
  border: 2px solid #e51c38;
  position: relative;
}
ul.product_home_box2 li {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 50%;
  vertical-align: top;
}
ul.product_home_box2 li:first-child {
  /* border: 2px solid #f5a64d; */
  position: relative;
}
.img100 img {
  width: 100%;
  display: block;
}
.prd_txt {
  padding: 60px 20px 130px;
  width: 100%;
}
.about_offerings_row li .offer_txt p strong,
.prd_txt p strong,
ul.about_vission_box li .prd_txt p strong,
ul.about_vission_box2 li .prd_txt p strong {
  font-weight: 600;
  color: #e51c38;
}
.btn-with-bg {
  width: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  padding: 12px 20px 12px 0;
  /* background: url(../images/design-bg/btn-bg.png) -50px 0 no-repeat; */
}
.quick_stats_home {
  padding: 0 0 100px;
  /* background: url(../images/design-bg/quick-bg.png) bottom right no-repeat; */
}
.quick_stats_row {
  width: 100%;
  padding: 40px 0;
  text-align: center;
}
.quick_stats_row ul li {
  vertical-align: top;
  display: inline-block;
  width: 14%;
  border-right: 1px solid #444;
}
.quick_stats_row ul li:last-child {
  border: none;
}
.quick_stats_row ul li h3 {
  color: #e51c38;
  padding: 0;
  font-size: 35px;
  font-weight: 600;
}
.quick_stats_row ul li p {
  margin: auto;
  line-height: 1.2;
}
.project-home-title {
  /* background: url(../images/design-bg/project-bg.png) 0 0 / cover repeat-x; */
  padding: 70px 0;
  text-align: center;
}
.project-home-title p {
  color: #fff;
  margin: 0;
  padding: 0;
}
.project-home-title.rise_btn .button-red a {
  background: #fff;
  color: #c61e38;
  font-size: 22px;
}
.bd_box_left,
.ong_project,
.owl_what_new {
  margin: 0;
}
.ong_txt {
  border: 1px solid #e51c38;
  position: relative;
  display: flex;
  align-items: center;
}
img.bg-project {
  position: relative;
  z-index: 0;
  left: 0;
  top: 0;
}
img.arrow-down,
img.arrow-up {
  width: 60px !important;
  left: 50%;
  z-index: 5;
  margin: 0 0 0 -30px;
  position: absolute;
}
.ong_txt_inner {
  position: absolute;
  z-index: 5;
  margin: auto 22%;
  text-align: center;
}
img.arrow-down {
  top: 0;
}
img.arrow-up {
  bottom: 0;
}
.owl_project .owl-buttons {
  display: flex;
  position: absolute;
  bottom: 48%;
  width: 100%;
  left: 0;
  z-index: 10;
}
.owl_acc_certifications .owl-next.disabled,
.owl_ongoing_project .owl-next.disabled,
.owl_project .owl-next.disabled,
.owl_what_new .owl-next.disabled,
.owl_what_new1 .owl-next.disabled {
  /* background: url(../images/icon/home-arrow-left-b.png) top center no-repeat; */
}
.owl_acc_certifications .owl-next,
.owl_ongoing_project .owl-next,
.owl_project .owl-next,
.owl_what_new .owl-next,
.owl_what_new1 .owl-next {
  position: absolute;
  right: 0;
  cursor: pointer;
  /* background: url(../images/icon/home-arrow-left-b.png) bottom center no-repeat; */
}
.owl_acc_certifications .owl-next:hover,
.owl_ongoing_project .owl-next:hover,
.owl_project .owl-next:hover,
.owl_what_new .owl-next:hover,
.owl_what_new1 .owl-next:hover {
  /* background: url(../images/icon/home-arrow-left-b.png) bottom center no-repeat; */
}
.owl_acc_certifications .owl-prev,
.owl_ongoing_project .owl-prev,
.owl_project .owl-prev,
.owl_what_new .owl-prev,
.owl_what_new1 .owl-prev {
  position: absolute;
  left: 0;
  cursor: pointer;
  /* background: url(../images/icon/home-arrow-right-b.png) bottom center no-repeat; */
}
.owl_acc_certifications .owl-prev:hover,
.owl_ongoing_project .owl-prev:hover,
.owl_project .owl-prev:hover,
.owl_what_new .owl-prev:hover,
.owl_what_new1 .owl-prev:hover {
  /* background: url(../images/icon/home-arrow-right-b.png) bottom center no-repeat; */
}
.owl_acc_certifications .owl-prev.disabled,
.owl_ongoing_project .owl-prev.disabled,
.owl_project .owl-prev.disabled,
.owl_what_new .owl-prev.disabled,
.owl_what_new1 .owl-prev.disabled {
  /* background: url(../images/icon/home-arrow-right-b.png) top center no-repeat; */
}
.solar-home-inner {
  margin: 80px 0 0;
  /* background: url(../images/home/solar-bg.jpg) 0 0 / cover repeat-x; */
  padding: 60px 0;
  text-align: center;
}
.solar_box {
  transition: 0.3s;
  background: #f5f5f5;
  padding: 50px 0 30px;
  display: none;
}
.sol_bt button,
.submit_btn_cont {
  transition: 0.4s;
  cursor: pointer;
  text-transform: uppercase;
}
.solar_box p {
  font-weight: 600;
  color: #444;
  padding: 0;
}
.solarbox_l {
  position: relative;
  height: 100%;
  float: left;
  width: 29%;
  text-align: center;
}
.solarbox_l h3 {
  margin: 0 0 2px;
  font-weight: 600;
  color: #e51c38;
  padding: 0;
}
.solarbox_r {
  float: right;
  width: 70%;
}
.inr_sp1 {
  padding: 15px;
}
.solar_form {
  width: 95%;
  margin: 10px auto;
}
.minform,
.minform1 {
  padding: 8px 5px;
  margin: 5px 0;
  background: 0 0;
  width: 100%;
  font-size: 16px;
}
.minform {
  border: 1px solid #444;
}
.minform1 {
  border: 0;
  border-bottom: 1px solid #444;
}
.sol_bt button {
  border: 0;
  margin: auto;
  background: #e51c38;
  border-radius: 0;
  text-align: center;
  color: #fff;
  display: block;
  padding: 6px 20px;
  font-size: 16px;
}
.tabs-new nav ul li {
  position: relative;
  width: 100%;
}
.sol_menu ul {
  display: flex;
  text-align: center;
}
.tabs-new nav li.tab-current {
  background: #f2a236;
}
.sol_menu {
  overflow: hidden;
  background: #e9e9e9;
  border-radius: 5px;
}
.tabs-new nav a {
  padding: 13px 0;
  font-size: 14px;
  color: #000;
  display: block;
}
.owl_what_new .item {
  margin: 0 7%;
}
.owl_acc_certifications .owl-buttons,
.owl_ongoing_project .owl-buttons,
.owl_what_new .owl-buttons {
  display: flex;
  position: absolute;
  bottom: 55%;
  width: 100%;
  left: 0;
  z-index: 10;
}
.iner_mg {
  width: 100%;
  margin-top: 30px;
  position: relative;
  min-height: 500px;
}
.slick-arrow {
  display: block !important;
}
.slider-products .slick-slide {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  outline: 0;
  padding: 18px 20px 18px 130px;
  text-align: center;
}
.slider-nav {
  margin-bottom: 12px;
}
.slider-nav .slick-current,
.slider-nav .slick-slide {
  cursor: pointer;
  opacity: 0.2;
}
.slider-nav .slick-current,
.slider-nav .slick-slide:hover {
  cursor: pointer;
  opacity: 1;
}
.slider.slider-nav.slider-1.slick-initialized.slick-slider,
.slider.slider-products.slider-1.slick-initialized.slick-slider {
  width: 50%;
  float: left;
  margin: 0;
  padding: 0;
}
.slider-nav {
  margin-top: 60px;
}
.slider-mg h2 {
  font-size: 18px;
  color: #e51c38;
  text-align: left !important;
  font-weight: 600;
  padding: 0;
  margin: 0;
}
.slider-mg p {
  font-size: 15px;
  color: #414042;
  text-align: left !important;
}
.slider-nav img {
  width: 100%;
  padding: 12px;
}
button.slick-prev.slick-arrow {
  cursor: pointer;
  /* background: url(../images/icon/arrow_left.png) bottom center no-repeat; */
  width: 84px;
  height: 38px;
  z-index: 99;
  margin-top: 30%;
  position: absolute;
  left: 0;
}
button.slick-next.slick-arrow {
  /* background: url(../images/icon/arrow_right.png) top center no-repeat; */
  width: 84px;
  height: 38px;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  text-align: left;
  margin: 30.8% auto 0 7%;
}
.tabcontent {
  float: left;
  padding: 0 !important;
  width: 70%;
  border-left: none;
  height: auto;
  border-radius: 0 10px 10px 0;
}
.like_this {
  padding: 90px 0;
}
.like_this_menu {
  text-align: center;
  padding: 30px 0 0;
}
.owl_what_new1 {
  margin: 40px 0 0;
}
.owl_what_new1 .item {
  margin: 0 20px;
  text-align: center;
}
.owl_what_new1 .owl-buttons {
  display: flex;
  position: absolute;
  bottom: 80%;
  width: 100%;
  left: 0;
  z-index: 10;
}
.owl_what_new1 img {
  width: 92% !important;
}
.contact_menu ul,
.like_this_menu ul {
  border: 1px solid #000;
  display: inline-block;
  text-align: center;
  margin: auto;
}
.contact_menu li,
.like_this_menu li,
.projects_menu li {
  display: inline-block;
  position: relative;
}
.contact_menu a,
.like_this_menu a {
  display: block;
  padding: 5px 20px;
  text-align: center;
  color: #000;
  font-weight: 600;
  font-size: 20px;
}
.contact_menu a.activ,
.contact_menu a:hover,
.like_this_menu a.activ,
.like_this_menu a:hover {
  background: #c61e38;
  color: #fff;
}
.hover_tab {
  padding: 50px 0 30px;
}
.hover_tab_col1 {
  float: left;
  width: 45%;
  position: relative;
}
.hover_tab_para {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
  background: #252a60a6;
}
.hover_tab_col1 .tabs-panel {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: 0.5s;
}
.hover_tab_col1 .is-active {
  width: 100%;
  height: auto;
  opacity: 1;
}
.hover_tab_col2 {
  float: right;
  width: 50%;
  padding: 30px 10px;
}
.hover_tab_col2 a {
  transition: 0.5s;
  font-size: 27px;
  padding: 10px 0;
  display: block;
  color: #676988;
}
.hover_tab_col2 a::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% + 16px);
  width: 0;
  height: 10px;
  background-color: #fff;
  content: "";
  transition: 0.5s;
  opacity: 0;
}
.hover_tab_col2 .is-active a::after {
  width: 110px;
  opacity: 1;
}
.sub_header_banner {
  background: #000;
  margin-top: 4.6rem;
}
.about-header {
  /* background: url(../images/sub-header/about-bg.jpg) center top/cover no-repeat; */
}
.head_title {
  height: 330px;
  display: flex;
}
.head_title h1 {
  font-size: 40px;
  font-weight: 600;
  color: #005E8C;
  margin: 0;
  padding: 0;
}


.about_page_row {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}
.disclaimer {
  text-align: left !important;
  width: 80% !important;
}
.about_vission {
  padding: 100px 0 0;
  
}
.about_vission-contain {
  padding: 30px 0 0;
}
ul.about_vission_box li {
  overflow: hidden;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
}
ul.about_vission_box li:first-child {
  align-self: flex-end;
  width: 40%;
}
ul.about_vission_box li:last-child {
  background: #fff;
  /* border: 2px solid #f39021; */
  /* border-left: 15px solid #f39021; */
  position: relative;
  width: 60%;
}
ul.about_vission_box2 li {
  overflow: hidden;
  margin: 0;
  padding: 0;
  vertical-align: top;
}
ul.about_vission_box2 li:first-child {
  background: #fff;
  width: 40%;
  border: 2px solid #e51c38;
  border-right: 15px solid #e51c38;
  position: relative;
}
ul.about_vission_box2 li:nth-child(2) {
  background: #fff;
  width: 60%;
}
ul.about_vission_box li .prd_txt {
  padding: 40px 20px 20px;
  width: 100%;
}
ul.about_vission_box2 li .prd_txt {
  padding: 40px 20px 0;
  width: 100%;
}
.philosophy_section {
  padding: 150px 0 0;

}




.submit_btn_cont {
  display: inline-block;
  padding: 5px 30px;
  background: #c61e38;
  color: #fff;
  border: 0;
  font-size: 20px;
}

.ongoing_project {
  padding: 30px 0 60px;
  margin-top: 60px;
  /* background: url(../images/project/ongoing-txt-bg.jpg) top left no-repeat; */
}
.ongoing_project_inner {
  padding-top: 0;
}
.owl_ongoing_project .item {
  width: 800px;
  margin: 0 auto;
  position: relative;
}
.ongoing_project .proj_back_img {
  width: 600px;
  margin-right: 20%;
  position: relative;
  left: 0;
  top: 0;
  z-index: 0;
  display: flex;
}
.ongoing_project .proj_txt_details {
  position: absolute;
  top: 20%;
  right: 0;
  width: 300px;
  -webkit-box-shadow: 1px 0 18px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 0 18px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 0 18px -4px rgba(0, 0, 0, 0.75);
}
.alignright a,
.completed_project .proj_txt_details p strong,
.ongoing_project .proj_txt_details p strong {
  color: #e51c38;
  font-weight: 600;
}
.completed_project {
  padding: 30px 0 60px;
  margin-top: 60px;
  /* background: url(../images/project/completed-txt-bg.jpg) top right no-repeat; */
}
.completed_project .proj_back_img {
  width: 600px;
  margin-left: 20%;
  position: relative;
  left: 0;
  top: 0;
  z-index: 0;
}
.completed_project .proj_txt_details {
  position: absolute;
  top: 20%;
  left: 0;
  width: 300px;
  -webkit-box-shadow: 1px 0 18px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 0 18px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 0 18px -4px rgba(0, 0, 0, 0.75);
}
.solar_roof_prod li {
  width: 50%;
}
.solar_txt_prod {
  padding: 0 10%;
}
.quick_fact_row li {
  width: 48%;
}
.quick_fact_row li:hover,
.quick_fact_row_3 li:hover {
  border: 2px solid #e51c38;
  /* background: url(../images/design-bg/qf-li-bg-over.jpg) top left/50px repeat-y; */
}
.quick_fact_row_3 li {
  width: 31.33%;
}
.qf_inner {
  padding: 0 0 0 10px;
  display: flex;
}
#textbox,
.qf_txt {
  padding: 0 10px;
}
.few_proj_txt span {
  color: #e51c38;
  font-weight: 500;
}
.customers_section {
  margin-top: 100px;
  /* background: url(../images/design-bg/btn-bg.png) right top no-repeat; */
}
.customers_section_inner {
  padding: 60px 0;
  /* background: url(../images/design-bg/btn-bg.png) left bottom no-repeat; */
}
.customers_row {
  margin: 0;
  /* background: url(../images/products/solar-roof-top/bg-customer.jpg) 0 0 / cover'
    repeat-x fixed; */
  padding: 60px 0;
}
.owl_customers {
  color: #fff;
  margin: 30px auto 0;
}
.owl_customers .item,
.owl_testimonials .item {
  padding: 0;
  margin: 0 auto;
  width: 600px;
  text-align: center;
}
.owl_customers .owl-buttons,
.owl_testimonials .owl-buttons {
  display: flex;
  position: absolute;
  bottom: 50%;
  width: 100px;
  right: 3%;
}
.project-csr.completed_project .proj_txt_details {
  width: 600px;
  position: initial;
  padding: 6px 106px;
  margin-left: 20%;
  margin-top: -6px;
  margin-bottom: 20px;
}
.button-red a.open {
  background: #f39021 !important;
}
.s_calculator {
  padding: 120px 0 60px;
}
.testmoni1 {
  margin: 50px 0 0;
  /* background: url(../images/design-bg/nisscall-bg.jpg) top left/5% repeat-y; */
  -webkit-box-shadow: -1px 1px 17px 0 rgba(0, 0, 0, 0.56);
  -moz-box-shadow: -1px 1px 17px 0 rgba(0, 0, 0, 0.56);
  box-shadow: -1px 1px 17px 0 rgba(0, 0, 0, 0.56);
}
.testmoni2 {
  padding: 50px 6%;
  /* background: url(../images/design-bg/nisscall-bg.jpg) top right/5% repeat-y; */
}
.owl_testimonials {
  padding-bottom: 50px;
}
.owl-prev {
  cursor: pointer;
  /* background: url(../images/icon/home-arrow-right.png) bottom center no-repeat; */
}
.owl_testimonials .owl-pagination {
  position: absolute;
  bottom: 0;
  left: 1%;
  right: 0;
  margin: auto;
  text-align: center;
}
.owl_testimonials p strong {
  color: #c61e38;
  font-weight: 600;
}
.accordion_4 {
  margin: auto;
  width: 100%;
}
.accordion_4 .acc-row {
  position: relative;
  margin-bottom: 10px;
  border: 2px solid #444;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.accordion_4 .accordion-header-icon_tab {
  /* background: url(../images/icon/faq-1.png) center no-repeat; */
  width: 37px;
  height: 37px;
  position: absolute;
  display: block;
  top: 5px;
  left: 10px;
  padding: 0 20px;
  font-size: 0;
}
.accordion_4 .accordion-header-icon_tab.accordion-header-icon-active_tab {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  /* background: url(../images/icon/faq-2.png) center no-repeat; */
  width: 37px;
  height: 37px;
}
.accordion_4 .min_box-h_tab h3 {
  padding-left: 70px;
  font-size: 18px;
}
.faq_txt {
  padding-left: 70px;
  padding-bottom: 10px;
  padding-right: 10px;
}
.faq_section {
  padding: 100px 0 50px;
}
.faq_row li {
  width: 50%;
  margin: 0 10px;
}
.zcwf_row {
  display: inline-block;
  vertical-align: top;
  width: 33%;
  padding: 10px;
}
.zcwf_row_full {
  display: block;
  width: 100%;
}
.zcwf_button {
  background: #887a7a;
  /* border: #ffdead; */
  padding: 8px 20px;
  color: #fff;
  margin: auto 10px;
  text-transform: uppercase;
  cursor: pointer;
}
.formsubmit {
  background: #c61e38;
}
.zcwf_row_btn {
  text-align: center;
  margin: 20px auto;
}
.zcwf_title {
  text-align: center;
  padding: 20px 0;
  color: #e51c38;
  font-weight: 600;
  font-size: 24px;
}
.enq-popup .zcwf_row {
  width: 49%;
}
.section_min {
  padding: 100px 0;
}
.warp-innner_sit {
  margin: auto;
  width: 80%;
}
.col3-sitemap {
  display: inline-block;
  width: 20%;
  margin: 0 1.5%;
  vertical-align: top;
}
.col3-sitemap a {
  color: #e51c38;
  font-size: 18px;
  display: block;
  margin: 0 0 10px;
}
.col3-sitemap h2 {
  font-weight: 400;
  padding: 10px 0 5px;
  font-size: 18px;
  color: #e51c38;
}
.col3-sitemap h2 a {
  color: #e51c38;
  font-size: 18px;
  padding: 0;
  margin: auto;
}
.col3-sitemap ul {
  margin: 10px 0 30px 10px;
  padding: 0 0 0 10px;
}
.col3-sitemap li {
  margin: 0;
  padding: 4px 0;
  font-size: 14px;
  list-style-type: disc;
}
.table-blue td,
.table-blue th {
  line-height: 22px;
  border-top: 1px solid #e51c38;
  font-size: 16px;
  text-align: center;
}
.col3-sitemap li a {
  color: #000;
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.col3-sitemap a:hover {
  color: #e51c38;
  opacity: 0.8;
}
.table-blue {
  padding: 0;
  font-weight: 700;
}
.table-blue table {
  border-left: 1px solid #e51c38;
  border-bottom: 1px solid #e51c38;
  color: #000;
}
.table-blue th {
  border-right: 1px solid #fff;
  background: #e51c38;
  color: #fff;
  padding: 12px 8px;
  text-transform: uppercase;
}
.table-blue td {
  border-right: 1px solid #e51c38;
  padding: 10px 8px;
}
@media only screen and (max-width: 1300px) {
  .container {
    width: 92%;
  }
  header .overlay {
    padding: 10px;
  }
}

@media only screen and (max-width: 800px) {
    .openings_row {
        padding: 10px 1rem;
    }
    .accordion_4 .min_box-h_tab h3{
      font-size: 16px;
      font-weight: bolder;
  }
  .sub_header_banner{
    margin-top: 0rem;
  }
  .current_openings, .management_sect{
    padding: 41px 0px;
  }
  .faq_txt{
    padding-left: 10px;
  }
  .accordion_4 .min_box-h_tab h3{
    padding-left: 10px;
  }
  .openings_row p{
    margin-top: 1rem;
  }
}



.carrmacss .accordion_4 .acc-row a{
color: #005E8C;
}

/* Basic styling for the cards */
.aboutcard .acard {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  /* padding: 0.5rem; */
  padding-left: 0.5rem;
}
.aboutcard h1{
  /* padding-left: 20.5rem; */
}

/* Styling for the image */
.aboutcard .acard .abouthead {
  width: 50%;
  height: auto;
  font-size: 2rem;
      color: rgba(12, 101, 237, 0.06);
      font-size: 152px;
      font-weight: 700;
      letter-spacing: 2px;
}

/* Styling for the content */
.aboutcard .acard .content {
  flex: 1;
  padding: 20px;
  /* padding-left: 35rem;  */
 
  padding-right: 4rem;
  /* text-align: right; */
}
.aboutcard h1{
  color: black;
  padding: 1rem;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .aboutcard .acard {
    flex-direction: column;
  }
  .aboutcard .acard .content {
    order: 1; /* Place content baelow image on smaller screens */
    padding-right: 0.5rem;
  }
}
.abouutcards{
  padding: 1rem;
}