@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input,
textarea {
  font-family: "Poppins", sans-serif;
}
.contactpage{
  margin-top: 3.4rem;
}

.contactpage .container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  /* background-color: #fafafa; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
  max-width: 100%;
  /* background-color: #fff; */
  border-radius: 10px;
  /* box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1); */
  /* z-index: 1000; */
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contactpage  .contact-form {
  background-color: #005E86;
  position: relative;
}

.contactpage  .circle {
  border-radius: 50%;
  /* background: linear-gradient(135deg, transparent 20%, #149279); */
  position: absolute;
}

.contactpage  .circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.contactpage  .circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contactpage  .contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  /* background-color: #1abc9c; */
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

.contactpage  form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.contactpage .title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.contactpage .input-container {
  position: relative;
  margin: 1rem 0;
}

.contactpage .input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: 0.3s;
}

.contactpage textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 5px;
  resize: none;
  overflow-y: auto;
}

/* .contactpage .input-container label {
   position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  transition: 0.5s; 
  z-index: 1000;
  
}

.contactpage .input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
} */

.contactpage .btn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  /* color: #1abc9c; */
  color: #005E8C;
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  width: 100%;
}
 .contactpage .input-container label {
  /* top: 5; */
  /* margin-top: 2rem; */
 color: white;
  z-index: 1000;
  
}
.contactpage .btn:hover {
  background-color: transparent;
  color: #fff;
}

.contactpage .input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.contactpage .input-container span:before,
.contactpage .input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: #1abc9c;
  top: 50%;
  transform: translateY(-50%);
}

.contactpage .input-container span:before {
  left: 50%;
}

.contactpage .input-container span:after {
  right: 50%;
}

.contactpage .input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.contactpage .input-container.focus span:before,
.contactpage .input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contactpage .contact-info {
  /* padding: 2.3rem 2.2rem; */
  position: relative;
}

.contactpage .contact-info .title {
  color: #005E86;
}

.contactpage .text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.contactpage .information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.contactpage .information i {
  color: #1ABC9C;
}

.contactpage .icon {
  width: 28px;
  margin-right: 0.7rem;
}

.contactpage .social-media {
  padding: 2rem 0 0 0;
}

.contactpage .social-media p {
  color: #333;
}

.contactpage .social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.contactpage .social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  /* background: linear-gradient(45deg, #1abc9c, #149279); */
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.contactpage .social-icons a:hover {
  transform: scale(1.05);
}

/* .contactpage .contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #1abc9c;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
} */

.contactpage .big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #1cd4af, #159b80);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.contactpage .big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.contactpage .square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

@media (max-width: 850px) {
    .contactpage  .form {
    grid-template-columns: 1fr;
  }

  .contactpage .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contactpage .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .contactpage .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .contactpage .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .contactpage .text {
    margin: 1rem 0 1.5rem 0;
  }

  .contactpage .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
    .contactpage .container {
    padding: 1.5rem;
  }

  .contactpage .contact-info:before {
    display: none;
  }

  .contactpage .square,
  .contactpage .big-circle {
    display: none;
  }

  .contactpage  form,
  .contactpage .contact-info {
    /* padding: 1.7rem 1.6rem; */
  }

  .contactpage  .text,
  .contactpage .information,
  .contactpage .social-media p {
    font-size: 0.8rem;
  }

  .contactpage .title {
    font-size: 1.15rem;
  }

  .contactpage .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .contactpage  .icon {
    width: 23px;
  }

  .contactpage .input {
    padding: 0.45rem 1.2rem;
  }

  .contactpage .btn {
    padding: 0.45rem 1.2rem;
  }
}
