/* Slideshow */

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }
  
  .slide {
    display: inline-block;
  
    /* height: 400px; */
    width: 100%;
    border-radius: 40px;
  }
  
  /* Buttons */
  
  .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  
    cursor: pointer;
    margin: 15px 7px 0px;
  
    /* background-color: #c4c4c4; */
  }
  
  .slideshowDot.active {
    /* background-color: #6a0dad; */
  }
  .demo-carousel{
    /* padding: 2rem;
   
    margin-top: 3rem; */
  }
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card {
    width: 300px; /* Set your desired width */
    margin: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-img {
    position: relative;
    overflow: hidden;
}

.card-img img {
    width: 100%;
    height: auto;
}

.card-body {
    padding: 20px;
}

.card-title {
    margin: 0;
    /* font-size: 24px; */
    text-transform: capitalize;
    /* font-weight: bold; */
}

.card-text {
    margin-top: 10px;
}
@import url('https://fonts.googleapis.com/css?family=Raleway');

/* Colors */
:root {
  --dark-blue: #32325d;
  --gray: #f8f8f8;
}

/* Mobile first */
*,
*::before,
*::after { 
  box-sizing: border-box;
}

html {
  background-color: var(--gray);
}

body {
  padding: 2rem 0.5rem;
  font-family: 'Raleway', Sans-serif;
  color: var(--dark-blue);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  margin: 0;
}

@media (min-width: 40rem) {
  body {
    padding: 2rem;
  }
}

/* Flex container */
.homecard-container {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  margin: 0;
}

/* Cards */
.homecard-container .cards {
  display: flex;
  padding: 1rem;
  margin-bottom: 2rem;
  width: 100%;
}

@media (min-width: 40rem) {
  .homecard-container .cards {
    width: 50%;
  }
}

@media (min-width: 56rem) {
 .homecard-container .cards {
    width: 33.3%;
  }
}

.homecard-container .card-item {
  display: flex;
  flex-direction: column;
  /* background-color: #fff; */
  width: 100%;
  border-radius: 6px;
  /* box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25); */
  overflow: hidden;
  transition: transform 0.5s;
}

.homecard-container .card-item:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.homecard-container .card-item:hover .card-image {
  opacity: 1;
}

/* Content */
.homecard-container .card-info {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
  line-height: 1.5em;
}

.homecard-container .card-title {
  font-size: 1.1rem;
  line-height: 1.1em;
  /* color: var(--dark-blue); */
  color: #015F8D;
  margin-bottom: 0.2em;
}

/* Images */
.homecard-container .card-image {
  height: 300px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px 6px 0px 0px;
  opacity: 0.91;
}
 .cardt{
    /* padding-left: 2rem; */
    font-size: 2rem;
    text-transform: capitalize;
    color: #015F8D;
}
 .ourmanufactures{
    padding: 0.5rem;
    padding-left: 3rem;
}
.three-column-images
{
  background:white;
}

.three-column-images .row
{
  text-align:center;
}
.three-column-images img
{
  margin-bottom:10px;
  margin-top:20px;
}

.three-column-images a
{
  font-weight:bold;
}
.ourmanufactures p{
  padding: 0.5rem;
}
.clients h2{
  font-size: 2.2rem;
  color: #015F8D;
  text-transform: capitalize;
  /* padding: 2rem; */
  margin-top: 2rem;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
}

.row {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping items inside rows */
  margin-bottom: 20px; /* Adjust as needed */
}

.image {
  width: calc(20% - 20px); /* Adjust width as needed */
  margin-right: 20px; /* Adjust spacing between images */
  margin-bottom: 20px; /* Adjust spacing between rows */
}

/* Adjust the width and spacing for different screen sizes using media queries */
@media (max-width: 768px) {
  .image {
    /* width: calc(33.33% - 20px);  */
    /* Adjust width for smaller screens */
  }
}

@media (max-width: 576px) {
  .image {
    width: calc(50% - 20px); /* Adjust width for even smaller screens */
  }
}
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15vw, 1fr)); /* Responsive grid with a minimum column width of 300px */
  gap: 30px; /* Adjust the gap between images as needed */
  margin: 0 5px 80px 40px;
  align-items: center;
}

.image-grid img {
  /* width: 15rem; */
  width: 50%;
  height: auto;
}
@media (max-width: 900px) {
  .image-grid {
    grid-template-columns: repeat(4, 1fr); /* Display 2 images per row on smaller screens */
    margin: 0; /* Remove margin to fit better in smaller screens */
    margin: 0 27px;
  }
}

@media (max-width: 500px) {
  .image-grid {
    grid-template-columns: repeat(3, 1fr); /* Display 2 images per row on smaller screens */
    margin: 0; /* Remove margin to fit better in smaller screens */
    margin: 0 30px;
    gap: 10px;
    /* margin-top: -; */
    /* padding-top: 1rem; */
    /* padding-bottom: 5rem; */
    margin-top: -2rem;
  }
  .image-grid img{
      width: 70%;
  }
  .komefi{
      margin-top: -2rem;
  }
  .solarkontl{
      /* padding-bottom: 5rem; */
      margin-top: -0.5rem;
  }
  .demo-carousel{
    /* padding: 2rem;*/
   
    margin-top: 3rem; 
  }
  .ourmanufactures{
    padding: 0.5rem;
    padding-left: 1rem;
  }
}
.iconpar{
  margin-right: 8px; /* Adjust the space between the icon and text */

}
.ourmanufactures ul {
  /* padding-left: -5rem; */
  margin-left: -1.5rem;
}